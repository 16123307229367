<template>
	<div class="Logs">

		<!-- 整体数据 -->
		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">系统管理</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">基础数据</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">帮助文档类别</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">帮助文档分类</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 整体数据 end-->

		<div class="frame_content mgtop10">

			<el-row :gutter="20">
				<el-col :span="4">
					<el-button size="small" type="primary" style="margin-bottom: 16px" icon="menu-fold"
						@click="newType">新增帮助文档类别</el-button>
					<el-menu class="menu-small" :default-active="0" @select="menuSelect" active-text-color="#ffffff">
						<el-menu-item v-for="(item,index) in helpTypeList" :key="index+''" :index="index+''">
							{{item.name}}</el-menu-item>
					</el-menu>
				</el-col>
				<el-col :span="20">

					<el-form :form="searchForm" inline class="ant-advanced-search-form">
						<el-form-item label="名称">
							<el-input size="small" placeholder="请填写名称" v-model="searchForm.name"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="doSearch"><i class="fa fa-search"></i>查询
							</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="success" size="small" @click="newItem"><i class="fa fa-plus"></i>新增分类
							</el-button>
						</el-form-item>
					</el-form>

					<el-table :data="helpClassificationList" :stripe="true" border>
						<el-table-column label="分类名称" prop="name" />
						<el-table-column label="自定义值" prop="selfVal" />
						<el-table-column label="所在分类" prop="fullName">
							<template slot-scope="scope">
								{{scope.row.fullName.replace("-"+scope.row.name,"")}}
							</template>
						</el-table-column>
						<el-table-column label="发布时间" prop="subTime" align="center" width="180px" />
						<el-table-column label="操作" key="action" align="center" width="200px">
							<template slot-scope="scope">
								<el-button type="primary" size="small" @click="showClassification(scope.$index)"><i
										class="fa fa-edit"></i>编辑</el-button>
								<el-button v-if="principal.level==3" type="danger" size="small"
									@click="removeClassification(scope.$index)"><i class="fa fa-remove"></i>删除
								</el-button>
							</template>
						</el-table-column>
					</el-table>

					<!-- 分页 -->
					<div class="wf overflow bgffffff mgtop10">
						<div class="pdding20">
							<el-pagination v-show="helpClassificationList.length>0" background
								layout="prev, pager, next" @current-change="pageChange" :page-size="searchForm.pageSize"
								:total="searchForm.total" class="fr">
							</el-pagination>
						</div>
					</div>

				</el-col>
			</el-row>
		</div>


		<!-- 分类信息 -->
		<el-dialog :close-on-click-modal="false" :title="curTypeName+'分类'" :visible.sync="dialogFormVisible">
			<el-form ref="helpClassificationForm" :model="helpClassificationForm" label-width="120px" :rules="rules">
				<el-row :gutter="24">
					<el-col :span="12">
						<el-form-item label="分类名称" prop="name">
							<el-input v-model="helpClassificationForm.name" placeholder="例如:新闻资讯" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="自定义值" prop="selfVal">
							<el-input v-model="helpClassificationForm.selfVal" placeholder="例如:xwzx或新闻资讯" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="所在分类" prop="superSerial">
							<el-select v-model="helpClassificationForm.superSerial" style="width:100%;">
								<el-option value="" label="顶级分类"></el-option>
								<el-option v-for="(level,index) in helpClassificationCacheList" :key="index"
									:value="level.serial" :label="level.name">
									<span v-for="(line,mi) in level.fullName.split('-').length-2"
										:key="mi">--</span>{{level.name}}
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="排序" prop="number">
							<el-input-number v-model="helpClassificationForm.number" style="width:100%;"
								placeholder="排序值在0～199之间" />
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishClassification">保存</el-button>
			</span>
		</el-dialog>
		<!-- 分类信息 end-->


	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				dialogMask: false,
				dialogzIndex: -1,
				tableLocale: {
					emptyText: '暂无数据'
				},
				dialogFormVisible: false,
				curTypeName: "",
				curTypeSerial: "",
				searchForm: {
					page: 1,
					pageSize: 20,
					superSerial: this.curTypeSerial,
					total: 0
				},
				typeSearchForm: {
					page: 1,
					pageSize: 20
				},
				helpTypeList: [],
				helpClassificationList: [],
				helpClassificationCacheList: [],
				helpClassificationForm: {},
				rules: {
					name: [{
							required: true,
							message: '请填写类别名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 20,
							message: '长度在 1 到 20个字符',
							trigger: 'blur'
						}
					],
					selfVal: [{
							required: true,
							message: '请填写自定义值',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 30,
							message: '长度在 1 到 30个字符',
							trigger: 'blur'
						}
					],
					number: [{
							required: true,
							message: '请填写排序',
							trigger: 'blur'
						},
						{
							type: "number",
							message: '排序为数字类型',
							trigger: 'blur'
						}
					]
				}
			}
		},
		methods: {
			newType() {
				window.location.href = "/m/db/help/type";
			},
			menuSelect(item) {
				console.log("菜单" + item.key, item);
				//获取当前类别的分类
				this.curTypeName = this.helpTypeList[parseInt(item)].name;
				this.curTypeSerial = this.helpTypeList[parseInt(item)].serial;
				this.searchForm.page = 1;
				this.searchForm.superSerial = this.curTypeSerial;
				this.searchForm.name = "";
				this.getClassificationList();
			},
			newClassification() {
				window.location.href = "/m/db/help/classification";
			},
			cancel() {
				this.dialogFormVisible = false
			},
			doSearch() {
				//查询
				this.searchForm.page = 1;
				this.getClassificationList();
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getClassificationList();
			},
			newItem() {
				this.dialogFormVisible = true
				this.helpClassificationForm = {
					number: 0
				};
			},
			gethelpTypeCacheList() { //从缓存获取项目类别
				this.$http.request(this, this.$apis.material.helpTypeList, {
					pageSize: 100
				}, false, res => {
					this.helpTypeList = res.data;
					console.log("项目类别已加载完", this.helpTypeList);
					//获取当前类别的分类
					this.curTypeName = this.helpTypeList[0].name;
					this.curTypeSerial = this.helpTypeList[0].serial;
					this.searchForm.superSerial = this.curTypeSerial;
					this.getClassificationList();
				});
			},
			getClassificationList() {
				this.$http.request(this, this.$apis.material.helpClassificationList, this.searchForm, false, res => {
					this.helpClassificationList = res.data;
					this.searchForm.total = res.count;
				});
			},
			getClassificationCacheList() {
				this.$http.request(this, this.$apis.material.helpClassificationCacheList, this.searchForm, false, res => {
					this.helpClassificationCacheList = res.data;
				});
			},
			publishClassification() {
				this.$refs['helpClassificationForm'].validate((valid) => {
					if (valid) {
						var helpClassificationForm = Object.assign({}, this.helpClassificationForm);
						helpClassificationForm.typeSerial = this.curTypeSerial;
						helpClassificationForm.updateTime = "";

						this.$http.request(this, this.$apis.material.helpClassificationPublish,
							helpClassificationForm, true,
							res => {
								let data = res.data;
								if (data != null) {
									this.helpClassificationList.push(data);
								}
								this.dialogFormVisible = false;
							});
					}
				});
			},
			showClassification(index) {
				console.log("absdsd", index)
				this.getClassificationCacheList();
				this.helpClassificationForm = this.helpClassificationList[index];
				this.dialogFormVisible = true;
			},
			removeClassification(index) {
				console.log(index, this.helpClassificationList[index])
				var helpClassification = this.helpClassificationList[index];
				this.$confirm('确定要删除帮助文档类别[' + helpClassification.name + '], 是否继续?', '提示', {
					conproductButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.material.helpClassificationRemove.replace("{serial}", helpClassification
						.serial)
					this.$http.request(this, this.$apis.material.helpClassificationRemove, {}, true, res => {
						this.helpClassificationList.splice(index, 1);
					});
				});
			}
		},
		created() {
			this.gethelpTypeCacheList();
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
